<template>
    <div class="main-wrapper">
        <div class="top-content" v-if="this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain' || this.$route.query.method === 'teaExam'">
            <!-- <el-breadcrumb separator=">" class="genera-breadcrumb">
                <el-breadcrumb-item>训练中心</el-breadcrumb-item>
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                <el-breadcrumb-item>发货地址管理</el-breadcrumb-item>
            </el-breadcrumb> -->
            <div class="btn-back">
                <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="answer">
            <p class="answer-title">学生提交答案</p>
            <el-table :data="deliverAddress"  class="customTable goods-text-table" style="width: 100%; flex: 1;">
                <el-table-column prop="where_address" label="所在地址" width="400px"></el-table-column>
                <el-table-column prop="detailed_address" label="详细地址" width="200px"></el-table-column>
                <el-table-column prop="postcode"  label="邮编" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                <el-table-column prop="ship_name"  label="发货人姓名" align="center"></el-table-column>
                <el-table-column prop="defualt" label="默认" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.defualt===1?'是':'否'}}</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div class="null-data">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </template>
            </el-table>
        </div>
        <div class="answer" v-if="this.$route.query.method === 'teaTrain'||this.$route.query.method === 'teaExam'||this.$route.query.method === 'teaExamTrain'">
            <p class="answer-title">得分答案</p>
            <el-table :data="teaDeliverAddress"  class="customTable goods-text-table" style="width: 100%; flex: 1;">
                <el-table-column prop="where_address" label="所在地址" width="400px"></el-table-column>
                <el-table-column prop="detailed_address" label="详细地址" width="200px"></el-table-column>
                <el-table-column prop="postcode"  label="邮编" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                <el-table-column prop="ship_name"  label="发货人姓名" align="center"></el-table-column>
                <el-table-column prop="defualt" label="默认" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.defualt===1?'是':'否'}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {stuOpTrainData,transactionTeaDetail,transactionTeaExamDetail,tranDistributionTeaExamDetail} from '@/utils/apis'
    export default {
        name: "DeliverAddressDetail",
        data(){
            return{
                train_id:Number(this.$route.query.train_id) || null,
                moduleId:Number(this.$route.query.moduleId) || null,//模板id
                deliverAddress:[],
                teaDeliverAddress:[],//得分答案
            }
        },
        mounted() {
            this.getStuOpTrainDataDetail()
        },
        methods:{
            //获取网店详情
            async getStuOpTrainDataDetail(){
                let params = {}
                let res
                if(this.$route.query.method === 'teaTrain'){
                    params.train_id = this.$route.query.trainId;
                    res = await transactionTeaDetail(params);
                    if (res.data.deliver_address.stu_answer){
                        this.deliverAddress = res.data.deliver_address.stu_answer
                    }
                    if (res.data.deliver_address.right_answer){
                        this.teaDeliverAddress = res.data.deliver_address.right_answer
                    }
                }else if(this.$route.query.method === 'stuTrain') { //学生训练
                    params.train_id = this.$route.query.id;
                    res = await stuOpTrainData(params)
                    //库存管理
                    if (res.data.deliver_address.stu_answer){
                        this.deliverAddress = res.data.deliver_address.stu_answer
                    }
                }else {
                    params.user_id = this.$route.query.id // 学生id
                    params.exam_id = this.$route.query.examId
                    //库存管理
                    if (this.moduleId == 40) {
                        res = await transactionTeaExamDetail(params)
                        if (res.data.deliver_address.stu_answer){
                            this.deliverAddress = res.data.deliver_address.stu_answer
                        }
                        if (res.data.deliver_address.right_answer){
                            this.teaDeliverAddress = res.data.deliver_address.right_answer
                        }
                    }
                    
                    //配送管理
                    if (this.moduleId == 41) {
                        res = await tranDistributionTeaExamDetail(params)
                        if (res.data.deliver_address.stu_answer){
                            this.exam_content = res.data.deliver_address.exam_content;
                            this.module = res.data.deliver_address.module;
                            this.totalScore1= res.data.deliver_address.total_score;
                            this.stu_score= res.data.deliver_address.stu_score;
                            this.deliverAdd.push(res.data.deliver_address)
                        }
                        if (res.data.sales_return_address.stu_answer){
                            this.exam_content = res.data.sales_return_address.exam_content;
                            this.module = res.data.sales_return_address.module;
                            this.totalScore2= res.data.sales_return_address.total_score;
                            this.stu_score= res.data.sales_return_address.stu_score;
                            this.salesReturnAddress.push(res.data.sales_return_address)
                        }
                        if (res.data.after_sales_return_address.stu_answer){
                            this.exam_content = res.data.after_sales_return_address.exam_content;
                            this.module = res.data.after_sales_return_address.module;
                            this.totalScore3= res.data.after_sales_return_address.total_score;
                            this.stu_score= res.data.after_sales_return_address.stu_score;
                            this.afterSalesReturnAddres.push(res.data.after_sales_return_address)
                        }
                        if (res.data.freight_template.stu_answer){
                            this.exam_content = res.data.freight_template.exam_content;
                            this.module = res.data.freight_template.module;
                            this.totalScore4= res.data.freight_template.total_score;
                            this.stu_score= res.data.freight_template.stu_score;
                            this.freightTemplate.push(res.data.freight_template)
                        }
                    }
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    }
</script>

<style scoped lang="scss">
    .main-wrapper{
        padding: 0 20px;
        .top-content{
            position: relative;
            .btn-back{
                position: absolute;
                // top: -12px;
                right: 20px;
            }
        }
        .answer-title{
            padding: 20px 0;
            margin: 0;
            font-size: 24px;
        }
    }

</style>